/* Ensure the body and html take up the full height of the viewport */
@font-face {
  font-family: 'Honk';
  src: url('./images/Honk/static/Honk-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


html,
body {
  height: 100%;
  margin: 0;
}

/* Ensure that the root container takes up the full height */
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* The main App container should fill the height except for the footer */
.App {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center
}

/* The main content area should expand to fill the available space */
.App-main {
  flex-grow: 1;
  padding: 20px;
  /* Optional: Add padding as needed */
}

.App-logo {
  height: 8vh;
  pointer-events: none;

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #214242;
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-left: 20px;
}

.Header-content {
  display: flex;
  align-items: center;


}

.Header-content p {
  margin-left: 2rem;
  font-size: 3rem;
  /* Apply the playful font */
  font-weight: bold;
  /* Optional: Make the text bold */
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Menu.css */
.Menu {
  display: flex;
  align-items: center;
  height: 12vh;
}

.Menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  /* Add padding to the left and right */
  border-left: 1px solid #4c5463;
  font-size: calc(8px + 2vmin);
  color: white;
  cursor: pointer;
  height: 100%;
  text-decoration: none;
  /* Ensure the box takes up the full height of the header */
  box-sizing: border-box;
  /* Ensure padding doesn't affect the height */
}

.Menu-item:hover {
  color: rgb(0, 0, 0);
}

.Menu-item:nth-child(1):hover {
  background-color: #ff9999;
}

.Menu-item:nth-child(2):hover {
  background-color: #ffcc99;
}

.Menu-item:nth-child(3):hover {
  background-color: #ffff99;
}

.Menu-item:nth-child(4):hover {
  background-color: #ccff99;
}

/* Specific hover styles for each Dropdown item */
.Dropdown-content .Dropdown-item:nth-child(1):hover {
  background-color: #99ccff;
}

.Dropdown-content .Dropdown-item:nth-child(2):hover {
  background-color: #cc99ff;
}

.Dropdown-content .Dropdown-item:nth-child(3):hover {
  background-color: #ff99cc;
}

.Dropdown {
  position: relative;
  display: inline-block;
  height: 100%;
}

.Dropdown-button {
  background-color: #214242;
  color: white;
  padding: 0 15px;
  font-size: calc(8px + 2vmin);
  border: none;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #4c5463;
}


.Dropdown-content {
  display: none;
  position: absolute;
  background-color: #152a2a;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
  right: 0;
  /* Align the dropdown content to the right edge of the button */
}

.Dropdown-content .Dropdown-item {
  color: white;
  padding: 20px 16px;
  text-decoration: none;
  display: block;
}

.Dropdown-content .Dropdown-item:hover {
  background-color: #575a5e;
}

.Dropdown:hover .Dropdown-content {
  display: block;
}

/* About.css */
.About {
  text-align: center;
  padding: 20px;
}

.About-image {
  width: 85%;
  height: auto;
  margin-bottom: 1rem;
}

/* Khan.css */
.Khan {
  text-align: center;
  padding: 20px;
}

.Khan-heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.Khan-logo {
  width: 21rem;
  /* Adjust the size as needed */
  margin: 20px 0;
  /* Ensure some spacing around the logo */
}

.Khan-pdf {
  width: 80%;
  height: 80vh;
  border: 1px solid #ccc;
  margin-top: 20px;
}

/* WishList.css */
.WishList {
  padding: 20px;
}

.WishList-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  /* Ensure all sections have the same height */
}

.WishList-section {
  flex: 1;
  margin: 0 20px;
  text-align: center;
  padding-bottom: 2rem;
  border-radius: 15px;
  background: linear-gradient(135deg, #ffffff, #ffffff);
  border: 5px solid transparent;
  background-clip: padding-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Distribute space evenly */
}

.WishList-section:before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  background: linear-gradient(135deg, #ff7eb9, #ff65a3, #7afcff, #feff9c);
  z-index: -1;
  border-radius: 20px;
}

.WishList-section h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
}

.WishList-image {
  width: 25rem;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 10px;
  align-self: center;
}

.WishList-links {
  list-style-type: none;
  padding: 0;
  text-align: center;
  flex-grow: 1;
  /* Ensure the list grows to fill available space */
}

.WishList-links li {
  margin-bottom: 10px;
}

.WishList-links a {
  font-size: 1.2rem;
  color: #1a73e8;
  text-decoration: none;
}

.WishList-links a:hover {
  text-decoration: underline;
}

/* Canvas.css */
.Canvas {
  text-align: center;
  padding: 20px;
}

.Canvas-logo-container {
  margin-bottom: 20px;
}

.Canvas-logo {
  width: 20rem;
  margin-top: 20px;
  cursor: pointer;
}

.Canvas-heading {
  font-size: 2rem;
  margin-bottom: 20px;
}

.Canvas-button-container {
  margin-bottom: 20px;
}

.Canvas-button-container button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  background-color: #0073e6;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.Canvas-button-container button:hover {
  background-color: #005bb5;
}

.Canvas-pdf {
  width: 80%;
  height: 80vh;
  border: 1px solid #ccc;
  margin-top: 20px;
}

.Canvas-links-image-container {
  display: flex;
  gap: 3rem;
  /* Adjust the spacing as needed */
  justify-content: center;
  align-items: center;
  /* Align items vertically in the center */
}

.MathResources-button-container {

  display: flex;
  width: 60%;
  justify-content: space-around;
  margin: 0 auto;
  margin-top: 3rem;
}

.MathResources-button-container button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.MathResources-logo {
  width: 13rem;
  margin-right: 3rem;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.MathResources-logo:hover {
  transform: translateY(-5px);
}

.image-button {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
}

.image-button img {
  display: block;
  width: 100%;
  height: auto;
}


.MathResources-pdf {
  margin-top: 0rem;
  width: 80%;
  height: 80vh;
  border: none;
}

.MathResources-additional {
  display: block;
  color: #237723;
  text-decoration: none;
  font-size: 1.7rem;
  font-weight: bold;
  padding: 10px;
  transition: transform 0.3s ease-in-out;
}

.MathResources-additional:hover {
  transform: translateY(-5px);
}

.Prodigy-login-link {
  margin: 3rem 0 2rem 0;
  width: 20rem;
  display: inline-block;
  padding: 10px 20px;
  background-color: #4CAF50;
  font-weight: bold;
  /* Green background */
  color: white;
  /* White text */
  text-decoration: none;
  /* Remove underline */
  border-radius: 5px;
  /* Rounded corners */
  font-size: 16px;
  /* Adjust font size */
  transition: background-color 0.3s ease;
  /* Smooth transition for hover */
}

.Prodigy-login-link:hover {
  background: #45a049;
}

.Boddle-parent-link {
  margin-top: 3rem;
  width: 22rem;
  display: inline-block;
  padding: 10px 20px;
  background-color: #4c82af;
  font-weight: bold;
  /* Green background */
  color: white;
  /* White text */
  text-decoration: none;
  /* Remove underline */
  border-radius: 5px;
  /* Rounded corners */
  font-size: 16px;
  /* Adjust font size */
  transition: background-color 0.3s ease;
  /* Smooth transition for hover */
}

.Boddle-parent-link:hover {
  background: #0a5a94;
}

.Footer {
  margin-top: 5rem;
  text-align: center;
  padding: 1rem 0;
  background-color: #f8f8f8;
  /* You can change this to your preferred background color */
  color: #333;
  /* You can change this to your preferred text color */
  font-size: 1rem;
}

.WitAndWisdom {
  text-align: center;
  margin-top: 50px;
}

.WitAndWisdom h1 {
  font-size: 2.5rem;
  color: #333;
}

.WitAndWisdom p {
  font-size: 1.5rem;
  color: #666;
}

.WitAndWisdom {
  width: 80%;
  /* Limit the width of the content */
  margin: 0 auto;
  /* Center the content */
  text-align: left;
  line-height: 1.6;
  /* Improve readability with spacing */
}

.WitAndWisdom h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #333;
  /* Darker color for contrast */
}

.WitAndWisdom p {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  color: #555;
  /* Softer text color */
}

.WitAndWisdom p.note {
  font-style: italic;
  text-align: center;
  /* Italicize the note */
  color: #900;
  /* Highlight the note with a different color */
}

.WitAndWisdom video {
  width: 100%;
  margin-top: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add a slight shadow for depth */
}

.WitAndWisdom {
  max-width: 80%;
  margin: 0 auto;
  padding: 20px;
}

.ww-video {
  width: 80%;
  margin: 20px 0;
}



.ww-resources-list li {
  margin-bottom: 10px;
}

.ww-link {
  color: #9500ff;
  text-decoration: none;
  font-weight: bold;
  margin-left: 5px;
}

.ww-link:hover {
  text-decoration: underline;
}

.Header-link {
  text-decoration: none;
  color: white;
}

.Header-link:hover {
  color: white;
  /* Optional: Keep the text white on hover */
}